// import './messaging_init_in_sw';
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import React, { useState } from "react";

function App() {
  
  const [Token, setToken] = useState([]);

  const firebaseConfig = {
    apiKey: "AIzaSyDW_iQL8CWsalwM2A7CdZaGSYfsroQ9qGw",
    authDomain: "lady-fiona.firebaseapp.com",
    projectId: "lady-fiona",
    storageBucket: "lady-fiona.appspot.com",
    messagingSenderId: "407742499106",
    appId: "1:407742499106:web:1aa61fe5d71ff4076dbd7b",
    measurementId: "G-JNER2KKRY4"
  };
  
  function requestPermission() {
    console.log("Requesting permission...");
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        console.log("Notification permission granted.");
        const app = initializeApp(firebaseConfig);
  
        const messaging = getMessaging(app);
        getToken(messaging, {
          vapidKey:
            "BM6esi6WMMpBDfwruc9hwp1rR6odbV6D9stS1tXCjz8L7gdlHXCUmGd2cGh6bxGbxq43MDncJrPM36whVPK7EwI",
        }).then((currentToken) => {
          if (currentToken) {
            console.log("currentToken: ", currentToken);
            setToken(currentToken);
          } else {
            console.log("Can not get token");
          }
        });
      } else {
        console.log("Do not have permission!");
      }
    });
  }
  
  requestPermission();

  return (
    <div className="App">
      <h2>{Token}</h2>
    </div>
  );
}

export default App;